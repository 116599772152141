﻿import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-datepicker/dist/css/bootstrap-datepicker.css'
import 'font-awesome/css/font-awesome.css'
import 'startbootstrap-sb-admin-2/dist/css/sb-admin-2.css'
import 'metismenu/dist/metisMenu.css'
import 'datatables.net-bs/css/dataTables.bootstrap.css'
import 'datatables.net-responsive-bs/css/responsive.bootstrap.css'
import 'select2/dist/css/select2.css'
import 'select2-bootstrap-theme/dist/select2-bootstrap.css'
import '../css/site.css';

import $ from 'jquery'
window.$ = $
import 'jquery-validation'
import 'jquery-validation-unobtrusive'
import 'bootstrap';
import 'bootstrap-datepicker'
import 'bootbox.js/bootbox.js'
import 'date.js'
import 'select2'
import 'startbootstrap-sb-admin-2/dist/js/sb-admin-2.js'
import 'metismenu'

// Import doesn't seem to work for datatables
require("datatables.net")(window, $);
require("datatables.net-bs")(window, $);
//require("datatables.net-responsive")(window, $);
//require("datatables.net-responsive-bs")(window, $);
//require("datatables.net-select")(window, $);

//import 'datatables.net-responsive'
//import 'datatables.net-responsive-bs'
//import 'dataTables.responsive'
//import 'datatables.net-bs/js/dataTables.bootstrap.js'
//import 'datatables.net-responsive/js/dataTables.responsive.js'
//import 'datatables.net-responsive-bs/js/responsive.bootstrap.js'
import './datepickerready.js'
import './validatedates-as-engb.js'

// Make bootbox availble to child pages
global.bootbox = require('bootbox');

$(function () {
    // Disable buttons on submit for a period of time
    $('form').submit(function () {
        if (!$(this).valid())
            return;

        var button = $(this).find(":submit").prop('disabled', true);
        setTimeout(function () {
            button.removeAttr('disabled');
        }, 8000);
    });

    // Tooltips
    $('[data-toggle="tooltip"]').tooltip()
});

$.noConflict();

// https://medium.com/@rich.wifidelity/asp-net-mvc-core-2-2-no-spa-with-webpack-4-jquery-bootstrap-babel-7-1f6584665566
window.WigAccreditation = {};


var Routes = {
    //Home: {
    //    init: function () {
    //        // controller-wide code
    //        import("./newJsPage").then((module) => {
    //            window.WigAccreditation.newJsPage = module.newJsPage;
    //        });
    //    },
    //    NewJsPage: function () {
    //        // action-specific code
    //        import("./newJsPage").then((module) => {
    //            window.WigAccreditation.newJsPage = module.newJsPage;
    //        });
    //    },
    //    Privacy: function () {
    //        // Privacy action code
    //    }
    //}
    Account: {
    },
    Contractor: {
        Home: {
            Index: function () {
                import("./contractor/home/contractorHome").then((module) => {
                    module.invoke()
                });
            },
            Create: function () {
                import("./contractor/home/contractorCreate").then((module) => {
                    module.invoke()
                });
            },
            Update: function () {
                import("./contractor/home/contractorUpdate").then((module) => {
                    module.invoke()
                });
            },
            GetRequiringProsecutionDetailsVerification: function () {
                import("./contractor/home/contractorRequiringProsecutionsDetailsVerification").then((module) => {
                    module.invoke()
                });
            },
        },
        Engineer: {
            Index: function () {
                import("./contractor/engineer/enginnerHome").then((module) => {
                    module.invoke()
                });
            },
            Create: function () {
                import("./contractor/engineer/engineerCreate").then((module) => {
                    module.invoke()
                });
            },
            Update: function () {
                import("./contractor/engineer/engineerUpdate").then((module) => {
                    module.invoke()
                });
            }
        },
        ContractorDocument: {
            init: function () {
                import("./sharedDocumentScript").then((module) => {
                    module.invoke(document.isAdmin)
                });
            },
            CDMRequiringVerification: function() {
                import("./contractorDocumentRequiringVerification").then((module) => {
                    module.invoke()
                });
            }
        },
        EngineerDocument: {
            init: function () {
                import("./sharedDocumentScript").then((module) => {
                    module.invoke(document.isAdmin)
                });
            },
            CertificateRequiringVerification: function () {
                import("./engineerDocumentRequiringVerification").then((module) => {
                    module.invoke()
                });
            }
        }
    },
    WIGAccreditationAdmin: {
        Users: {
            Index: function () {
                import("./wigaccreditationadmin/users").then((module) => {
                    module.invoke()
                });
            }
        }
    }
};

var Router = {
    exec: function (area, controller, action) {
        action = action === undefined ? "init" : action;

        var route = {
            "area": area,
            "controller": controller,
            "action": action
        }

        console.log("Executing route")
        console.log(route)

        if (controller !== "" && Routes[area][controller] && typeof Routes[area][controller][action] === "function") {
            console.log("Matched")
            Routes[area][controller][action]();
        } else {
            if (action !== "init") {
                console.log("Unmatched")
            }
        }
    },

    init: function () {
        let body = document.body;
        let area = body.getAttribute("data-area");
        let controller = body.getAttribute("data-controller");
        let action = body.getAttribute("data-action");

        document.isAdmin = body.getAttribute("data-isadmin");

        Router.exec(area);
        Router.exec(area, controller);
        Router.exec(area, controller, action);

    }
};

//run this immediately
Router.init();
